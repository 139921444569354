// ezentrum-Module: ArticleText
// Type: General 
// Doc:  Artikel-Text-Replacer 

// Globals:
// Doc: Definition of all global vars for the Artikel-Text Replacer
declare let ezentrum_art_attributes:any; // Available in ezentrum article detail page

export class ArticleText {

    public constructor() {
    }

    // Method: run()
    // Doc: Execute the text replacement.
    public run():void{
        this.replaceArticleDetailAttributes();
    }

    // Method: replaceArticleDetailAttributes
    // Doc: Process all matching html-elements identified by the class "ez-module-articletext" and sets the labels for the html-elements.
    // Example-HTML: <span class="ez-module-articletext" data-attributename="Alkohol"></span>
    public replaceArticleDetailAttributes ():void{
        let collection:HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName("ez-module-articletext") as HTMLCollectionOf<HTMLInputElement> ; // Identify all html-elements by classname
        for (let i = 0; i < collection.length; i++) // Process all html-elements
        {
            let attributeName:string=collection[i].dataset.attributename; // Get data-attribute as object key. 
            let replaceTxt:string=collection[i].dataset.replacetxt; // Get data-attribute for replace regex.
            let datatype:string=collection[i].dataset.type; // Data-attribute: (type:number|text)
            let datavalue:string=collection[i].dataset.value; // Data-attribute: (type:number|text)
            let compareOperator:string=collection[i].dataset.compareoperator; // Data-attribute (compareoperator)
            let dataformat:string=collection[i].dataset.format; // Data-attribute: (format)
            let arObjectKeys = Object.keys(ezentrum_art_attributes); // Get all object keys from global ezentrum object.
            let labelKey=arObjectKeys.find(element => element === attributeName); // Label exists?
            if (labelKey) // Label found?
            {
                switch(compareOperator){
                    case "==":  // Equals : number + default types
                    {
                        if ((typeof ezentrum_art_attributes[labelKey] !== 'undefined'))
                        {
                            let displayBlock=false
                            if (datatype==="number")
                            {
                                let compareValue:number=Number(datavalue)
                                displayBlock=(ezentrum_art_attributes[labelKey]===compareValue)
                            }
                            else
                                displayBlock=(ezentrum_art_attributes[labelKey]===datavalue)
                            if (!displayBlock)
                                collection[i].style.display = "none";
                        }
                        else
                            collection[i].style.display = "none";
                        break;
                    }
                    case "!=": // Not Equal (number + date + default type)
                    {
                        if ((typeof ezentrum_art_attributes[labelKey] !== 'undefined'))
                        {
                            let displayBlock=false;
                            switch(datatype){
                                case "number":  // Equals : number + default types
                                {
                                    let compareValue:number=Number(datavalue)
                                    displayBlock=(ezentrum_art_attributes[labelKey]!==compareValue)
                                    break;
                                }
                                case "date":  // Equals : number + default types
                                {
                                    let compareOps = datavalue.split(';'); // Split the conditions
                                    let doIt:boolean=compareOps.length>0; // Compare-Values are set
                                    let iOperator=0;
                                    let displayBlock:boolean=true;
                                    while (doIt)
                                    {
                                        let cpmValue:string=compareOps[iOperator];
                                        let found:number=ezentrum_art_attributes[labelKey].indexOf(cpmValue);
                                        if (displayBlock===true)
                                            displayBlock=(found===-1);
                                        iOperator++;
                                        doIt=(iOperator<compareOps.length)
                                    }
                                    break;
                                }
                                default :
                                {
                                    displayBlock=(ezentrum_art_attributes[labelKey]!==datavalue)
                                }
                            }
                            if (!displayBlock)
                                collection[i].style.display = "none";
                        }
                        else
                            collection[i].style.display = "none";
                        break;
                    }
                    case "true": // Boolean compare operations.
                    {
                        if (ezentrum_art_attributes[labelKey])
                            collection[i].style.display = "block";
                        else
                            collection[i].style.display = "none";
                        break;
                    }
                    case "empty": // Empty compare operations.
                    {
                        if (ezentrum_art_attributes[labelKey]==="")
                            collection[i].style.display = "none";
                        else
                            collection[i].style.display = "block";
                        break;
                    }
                    default: // Default compare operation -> RexEx Replacing.
                    {
                        let data=ezentrum_art_attributes[labelKey];
                        if (replaceTxt) // RegEX for data-value-replacement
                        {
                            data=data.replace(new RegExp(replaceTxt, 'g'), "");
                        }
                        if (dataformat)
                            data=this.format(data,dataformat);
                        collection[i].innerHTML=data; // Set the label for the identified element.
                    }
                }
            }else
            {
                collection[i].style.display = "none";
            }
        }
    }

    private format(data: any, data_format:string): String{
        let res:string=`${data}`;
        if (data_format==="number_comma") // Replace 3.56 -> 3,56
        {
            res=res.replace(/\./g,","); // Rel
        }
        if (data_format==="date_dd.mm.yyyy")
        {
            let d=new Date(res);
            res=d.getDay()+"."+d.getMonth()+"."+d.getFullYear();
        }
        return res;
    }
  
}