import { Helpers } from "./classes/handlebars/Helpers";
import { Logging } from "./classes/logging/Logging";
import { AjaxRequest } from "./libs/AjaxRequest";
import { Cookies } from "./libs/Cookies";
import { Elements } from "./libs/Elements";
import { Globals, KeyCodes, ModuleExecutionStatus } from "./classes/Globals";
import { Json } from "./libs/Json";
import { KeyEvents } from "./libs/KeyEvents";
import { Pair } from "./libs/Pair";
import { Tools } from "./libs/Tools";
import { Windows } from "./libs/Windows";
import { Module } from "./classes/mvc/Module";
import { Model } from "./classes/mvc/Model";
import { Times } from "./libs/Times";
import { Interfaces } from "./libs/Interfaces";
import { IOverlay } from "./classes/IOverlay";
import { Collapse } from "./classes/Collapse";
import { Strings } from "./libs/Strings";
import { ArticleText } from "../src/modules/Article/ArticleText";
import { EZModule } from "./classes/v2/EZModule";

require("./resources/styles/all.scss"); // Needed for Webpack CSS generation
var modulesSetup = require("./modules-setup.json");// lader der module-setup.json

declare let ezentrum_variables: any; // Global Var (All ezentrum-shop-vars)
declare let conf_file_path: any; // Global Var (All ezentrum-shop-vars)
declare var window: any; // Global Var (window -> General dom window)

export class Modules {
    // Public Static Variables
    public static VERSION = modulesSetup.version; // TODO: Check modul-setup --> It will be never used. Check to eleminate it.
    public static modulesVariables: any; // Globale eZentrum Variablen
    public static isInShopView: boolean; // Status: True => im Shop, Status: False => CMS
    public static isNewSessionStucture: boolean; // Status: True => neuer Aufbau, Status: False => Altes Modulsystem bis 05.07.2021
    // Public Variables
    public modulesConfiguration: Object;   // Inkludiert die Konfiguration für alle Module - conf.json / conf.yaml -> modules
    public modules: Array<Module<Model>>; // Global Array of all Modules
    // Privat Variables
    private globalConfigurations: Object;   // Inkludiert die Konfiguration für alle Module - conf.json / conf.yaml -> global
    private template: string; // Inkludiert die Konfiguration für alle Module - conf.json / conf.yaml -> z.B.: foundation
    private language: Pair<number, string>; // stellt die Sprache ein per ID
    private startTime: number; // Startzeit, wird beim Logging verwendet
    private endTime: number;    // Endzeit, wird beim Logging verwendet
    private flagAllowCookies: boolean;  // Zeigt an, ob Cookies erlaubt sind oder nicht
    private sKontaktID: string; // Session Kontakt ID
    private sKontaktKEY: string;    // Session Kontakt Key
    private sTICKCOUNT: string; // Session Tick Count
    
    // Constructor
    public constructor() {
    }

    // method: checkRequirements
    // returns false, if requirements are not ok.
    public checkRequirements(): boolean {
        var configuration: any = null; // eZentrum Modul System configurations
        var confFilePath: string = ""; // Pfad zu der Konfigurationsdatei
        if ( typeof conf_file_path !== 'undefined' )  // Exists global configuration var in html site?
            confFilePath = conf_file_path;
        else // Try to load the default yaml location
            confFilePath =  modulesSetup.defaultConfPath + ".yaml";
        configuration = AjaxRequest.getYaml(confFilePath);
        if ( configuration.global.use_bundle_jquery ) // Use global JQuery in ezentrum modul system?
        {
            window.jQuery = jQuery;
            window.$ = jQuery;
        }
        let language = document.documentElement.lang; // Get the html document language
        if ( typeof ezentrum_variables !== 'undefined' )  // Is global var ezentrum_variables set in the html? 
        {
            Modules.isInShopView = true;
            this.sKontaktID = ezentrum_variables.sKontaktID;
            this.sKontaktKEY = ezentrum_variables.sKontaktKEY;
            this.sTICKCOUNT = ezentrum_variables.sTICKCOUNT;
        } else 
        {
            // Block: Scope is outside the shop system --> Load the session vars from the shopping system.
            Modules.isInShopView = false;
            let url: string;
            if ( configuration.global.sessionVars || configuration.global.sessionVars !== '' ) // Shop with one language? global.sessionVars="url_to_sync" is set.
                url = configuration.global.sessionVars;
            else // Shop with multi-language: global.sessionVars_lang definition is set.
            {
                let sessionvars_url = configuration.global.sessionVars_lang.find((obj:any) => obj.html_lang === language);
                url = sessionvars_url.url;
            }
            Modules.modulesVariables = AjaxRequest.getPlainText(url); // Receive the shopping session vars from the above idendified setup in the yaml-file
            if (Modules.modulesVariables.customer_sync) // Sync elements for external sites (Customer, Basket, New Products, Session-Id, Session-Key, Tickcount)? --> If customer_sync, process all elements.
            {
                let customerHTMLElement:HTMLElement=document.getElementById("ezentrum-header-customer") as HTMLElement; // Customer sync
                if (customerHTMLElement)
                    customerHTMLElement.innerHTML=Modules.modulesVariables.customer_sync // set customer values 
                let customerMobileHTMLElement:HTMLElement=document.getElementById("ezentrum-header-mobile-customer") as HTMLElement; // Customer mobile sync
                if (customerMobileHTMLElement)
                    customerMobileHTMLElement.innerHTML=Modules.modulesVariables.customer_sync_mobile // set customer values 
                let basketMobileHTMLElement:HTMLElement=document.getElementById("ezentrum-header-mobile-basket") as HTMLElement; // Basket mobile sync
                if (basketMobileHTMLElement)
                    basketMobileHTMLElement.innerHTML=Modules.modulesVariables.basket_sync_mobile // set customer mobile basket values 
                let basketHTMLElement:HTMLElement=document.getElementById("ezentrum-header-basket") as HTMLElement; // Shopping Basket sync
                if (basketHTMLElement)
                    basketHTMLElement.innerHTML=Modules.modulesVariables.basket_sync // set basket value
                let newProductsHTMLElement: HTMLElement=document.getElementById("ez_newproducts") as HTMLElement; // New Products sync
                if (newProductsHTMLElement)
                    newProductsHTMLElement.innerHTML=Modules.modulesVariables.ads_new; // set offers
                let collection:HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName("ezentrum-session-key") as HTMLCollectionOf<HTMLInputElement> ; // Set all session-keys
                for (let i = 0; i < collection.length; i++) // Replace all session-keys for the identifed html-elements in the document.
                {
                    collection[i].value = Modules.modulesVariables.required.contact_id;
                }
                collection = document.getElementsByClassName("ezentrum-session-id") as HTMLCollectionOf<HTMLInputElement> ; // Set all session-contact-keys
                for (let i = 0; i < collection.length; i++)  // Replace all session-ids for the identifed html-elements in the document.
                {
                    collection[i].value =  Modules.modulesVariables.required.contact_key;
                }
                collection = document.getElementsByClassName("ezentrum-session-tickcount") as HTMLCollectionOf<HTMLInputElement> ; // Set all sesion-tickcounts
                for (let i = 0; i < collection.length; i++)  // Replace all session-tickcount for the identifed html-elements in the document.
                {
                    collection[i].value =  Modules.modulesVariables.required.tickcount;
                }
                // Block: 
            }
            if ( typeof Modules.modulesVariables === "string" ) {
                Modules.modulesVariables = this.parseTextToJson( Modules.modulesVariables );
            }
            if ( Modules.modulesVariables.hasOwnProperty("required")) // Identify the session-structure and set the global session status for handling the initialization.
                Modules.isNewSessionStucture = true;
            else
                Modules.isNewSessionStucture = false;
            if ( Modules.isNewSessionStucture ) // Init the sessionid, key + tickcount as identified session-structure
            {
                this.sKontaktID = Modules.modulesVariables.required.sKontaktID;
                this.sKontaktKEY = Modules.modulesVariables.required.sKontaktKEY;
                this.sTICKCOUNT = Modules.modulesVariables.required.tickcount;
            } else {
                this.sKontaktID = Modules.modulesVariables.sKontaktID;
                this.sKontaktKEY = Modules.modulesVariables.sKontaktKEY;
                this.sTICKCOUNT = Modules.modulesVariables.sTICKCOUNT;
            }
        }
        if ( Tools.isDefinedVar( this.sKontaktID, this.sKontaktKEY, this.sTICKCOUNT ) ) {
            this.template = "foundation";
            this.language = new Pair(1, "de");
            this.startTime = new Date().getTime();
            this.endTime = new Date().getTime();
            this.flagAllowCookies = true;
            this.globalConfigurations = new Array();
            this.modules = new Array();
            if ( Modules.isInShopView ) {
                if ( ezentrum_variables.template ) {
                    this.template = ezentrum_variables.template;
                }
            } else {
                if ( Modules.modulesVariables.template ) {
                    this.template = Modules.modulesVariables.template;
                }
            }
            KeyEvents.registerEvent( [ KeyCodes.CTRL, KeyCodes.CMD_LEFT, KeyCodes.L ], this.printLogEntrys.bind(this) ); // Error-Log-Consol Output
            if ( configuration ) {
                this.modulesConfiguration = Json.getSubobject( configuration, "modules" );
                this.globalConfigurations = Json.getSubobject( configuration, "global" );
                if ( this.modulesConfiguration == null ) {
                    return false;
                } else {
                    var languageID: number;

                    if ( Modules.isInShopView ) {
                        if ( ezentrum_variables.current_language ) {
                            languageID = ezentrum_variables.current_language;
                        }
                    } else {
                        if ( Modules.isNewSessionStucture ) {
                            languageID = Modules.modulesVariables.required.language_number;
                        } else {
                            languageID = Modules.modulesVariables.current_language;
                        }
                    }

                    var languageCode: string = this.getGlobalConfig( "language_mapping." + languageID );
                    if ( languageID && languageCode ) {
                        this.language = new Pair(languageID, languageCode);
                    }
                    return true;
                }
            } else {
                this.error("Die Konfigurationsdatei konnte nicht geladen werden oder ist Fehlerhaft.", false);
                this.error("Pfad: " + confFilePath, false);
                return false;
            }
        } else {
            this.error("Die benötigten Variablen wurde nicht gefunden: sKontaktID, sKontaktKEY und sTICKCOUNT", false);
            return false;
        }
    }

    public preInit(): void {
        Windows.start();
        KeyEvents.start();
        Cookies.start();
    }

    public init(): void {
        Helpers.init();
        Globals.init();
        Elements.setFocusOnClick();
        if ( this.getGlobalConfig("prevent_special_chars_in_password_fields") == true ) {
            Elements.blockSpecialChars();
        }
        this.includeModules();
        this.includeModulesV2();
    }

    public afterInit(): void {
        Collapse.init();
    }

    private isInDoc(moduleName: string,processedModules:Array<any>): boolean {
        let res:boolean=false;
        if (processedModules.includes(moduleName)) { return res; }
        let tempElement=document.getElementsByClassName("ez-" + moduleName.toLowerCase());
        if (tempElement){
            if (tempElement.length === 0) {
                res = false;
            }
            else {
                res = true;
            }
        }
        return res;
    }

    // Method: includeModulesV2
    // Loading Modules V2
    private async includeModulesV2(): Promise<void> {
        let moduleNames: Array<string> = Object.keys( this.modulesConfiguration ); // Receive the module configuration.
        let processedModules:Array<any>=[];
        for ( let i = 0; i < moduleNames.length; i++ ) // Loop through all module configuration and process the loading for Modules-V2
        {
            let moduleName: string = moduleNames[i];
            let modulesConfiguration: any = Json.getSubobject( this.modulesConfiguration, moduleName );
            if ( modulesConfiguration !== null && modulesConfiguration.type === "v2") // Is Module V2?
            {
                if (this.isInDoc(moduleName,processedModules)) // Is Module in HTML-Site?
                {
                    processedModules.push(moduleName);
                    let widget = await import ("./modules/" + moduleName + "/" + moduleName); // Import the module source -> Lazy loading
                    let widgetExports: Array<string> = Object.keys(widget); // Handling the module exports for classes
                    for (let j = 0; j < widgetExports.length; j++) // Loop through all identfied export classes and call the constructor to include the classes.
                    {
                        let widgetExport: ObjectConstructor = widget[widgetExports[j]]; // Get object constructor
                        if (widgetExport.prototype instanceof EZModule) // Is class derived from the abstract class EZModule?
                        {
                            let ez_module: EZModule = new widget[widgetExports[j]](modulesConfiguration); // Create the Module Object with the modul-configurations.
                            ez_module.init(); // Inialize the Module
                            ez_module.run(); // Run Module and assign all events
                        }
                    }
                }
            }
        } 
    }

    // Modules will be loaded, if the html-element has the attribute: data-ez-module-[Modulename]
    // So please ensure, that all modules will have the data-ez-module-[Modulename] html attribute.
    private async includeModules(): Promise<void> {
        // Section: Loading General Modules
        let ezentrumArticleText:ArticleText=new ArticleText();    // Loading Artikel-Text Replacer
        ezentrumArticleText.run();
        // Loading Dynamic Modules
        let loadSearchModule:boolean=false;
        let moduleNames: Array<string> = Object.keys( this.modulesConfiguration );
        for ( let i = 0; i < moduleNames.length; i++ ) {
            let moduleName: string = moduleNames[i];
            let modulesConfiguration: any = Json.getSubobject( this.modulesConfiguration, moduleName );
            if ( modulesConfiguration != null && modulesConfiguration.type !== "v2" ) {
                try {
                    let moduleElement = jQuery( "[data-ez-module-" + moduleName + "]" ); // Find the module in the html-site
                    if ( moduleElement.length > 0 ) {
                        let widget = await import( "./modules/" + moduleName + "/Module" + moduleName );
                        let widgetExports: Array<string> = Object.keys( widget );
                        for ( let j = 0; j < widgetExports.length; j++ ) {
                            let widgetExport: ObjectConstructor = widget[widgetExports[j]];
                            if ( widgetExport.prototype instanceof Module ) {
                                let module: Module<Model> = new widget[widgetExports[j]](modulesConfiguration);
                                if ( module.getName() == "CookieDirective" && module.isActive() ) {
                                    this.flagAllowCookies = false;
                                }
                                this.modules.push( module );
                            }
                        }
                    }
                } catch (e) {
                    this.error( "Das folgende Modul existiert nicht oder konnte nicht aufgerufen werden: " + moduleName, false );
                    this.error( "Pfad: src/modules/" + moduleName + "/Module" + moduleName + ".ts", false );
                }
            }
        }
        this.initModules();
        for ( let i = 0; i < this.modules.length; i++ ) {
            if ( this.modules[i].isActive() ) {
                for ( let j = 0; j < this.modules[i].getControllers().length; j++ ) {
                    if ( Interfaces.implements( this.modules[i].getControllers()[j], ["openPopup", "closePopup"] ) ) {
                        try {
                            var overlayController: IOverlay = this.modules[i].getControllers()[j] as any;
                            Globals.OVERLAY.getElement().addEventListener("click", overlayController.closePopup.bind(overlayController))
                        } catch(e) {  }
                    }
                }
            }
        }
        this.endTime = new Date().getTime();
    }

    /**
     * Method: loadOneModule
     * loads dynamically one module
     * @param moduleName 
     */
    public async loadOneModule(moduleName:string):Promise<void> {
        let modulesConfiguration1: Object = Json.getSubobject( this.modulesConfiguration, moduleName );
        try {
            let widget = await import( "./modules/" + moduleName + "/Module" + moduleName );
            let widgetExports: Array<string> = Object.keys( widget );
            for ( let j = 0; j < widgetExports.length; j++ ) {
                let widgetExport: ObjectConstructor = widget[widgetExports[j]];
                if ( widgetExport.prototype instanceof Module ) {
                    let module: Module<Model> = new widget[widgetExports[j]](modulesConfiguration1);
                    this.modules.push( module );
                }
            }
        } catch (e) {
            this.error( "Das folgende Modul existiert nicht oder konnte nicht aufgerufen werden: " + moduleName, false );
            this.error( "Pfad: src/modules/" + moduleName + "/Module" + moduleName + ".ts", false );
        }
    }

    public initModules(): void {
        for ( let i = 0; i < this.modules.length; i++ ) {
            this.initModule( this.modules[i], false);
        }
        this.afterInit();
    }

    public initModule( module: Module<Model>, reInit: boolean = false ): void {
        if ( module.readyToRun() || reInit ) {
            if ( module.isActive() ) {
                if ( !( !this.flagAllowCookies && module.useCookies() ) ) {
                    try {
                        if ( reInit ) {
                            module.runAllInitializedControllers();
                        } else {
                            module.runWithPreCheck();
                        }
                        if ( !module.foundError() ) {
                            module.callGlobalModuleEventFunction( "loaded" );
                            module.setExecutionStatus( ModuleExecutionStatus.SUCCESSFULL );
                        } else {
                            module.setExecutionStatus( ModuleExecutionStatus.CATCHED_ERROR );
                        }
                    } catch (error) {
                        module.setInitializationCompleted();
                        module.setExecutionStatus( ModuleExecutionStatus.NOT_CATCHED_ERROR );
                        this.error( "Fehler im Modul: " + module.getName(), false );
                        this.error( error, false );
                    }
                } else {
                    module.setExecutionStatus( ModuleExecutionStatus.COOKIES );
                }
            }
        }
    }

    /**
     * 
     * Show the logging by pressing the following keys: Ctrl + Cmd + L
     */
    private printLogEntrys(): void {
        this.buildModulesLog();
        console.clear();
        console.log("%c*************************************", "color: gray;");
        console.log("%c*******   eZentrum Modules   ********", "color: gray;");
        console.log("%c*******   Version: " + Modules.VERSION + "    ********", "color: gray;");
        console.log("%c*************************************", "color: gray;");
        Logging.MODULES.print();
        this.log("Alle Module wurden in " + Times.getTimeString(this.endTime - this.startTime, "s") + " ausgeführt");
        Logging.GENERAL_LOG.print();
        Logging.ERROR_LOG.print();
    }

    private buildModulesLog() {
        Logging.MODULES.clear();
        for ( let i = 0; i < this.modules.length; i++ ) {
            let text: string = "%c" + this.modules[i].getName() + ": %c" + ( this.modules[i].isActive() ? "" : "in" ) + "aktiv";
            let style: Array<string> = new Array(
                "color: grey;",
                "color:" + ( this.modules[i].isActive() ? "green" : "red" ) + ";"
            );
            Logging.MODULES.addWithMultipleStyles( text, style );
        }
        Logging.MODULES.add("", "", false);
        for ( let i = 0; i < this.modules.length; i++ ) {
            let module: Module<Model> = this.modules[i];
            if ( module.isInitialized() && module.isActive() && !(!this.flagAllowCookies && module.useCookies()) ) {
                let moduleExecutionStatus: any = module.getExecutionStatus();
                if ( moduleExecutionStatus != null ) {
                    let color = new Array(
                        "color: grey;"
                    );
                    switch( moduleExecutionStatus ) {
                        case ModuleExecutionStatus.SUCCESSFULL:
                            color.push("color: green;");
                            break;
                        case ModuleExecutionStatus.NOT_CATCHED_ERROR:
                            color.push("color: red;");
                            break;
                        default:
                            color.push("color:orange;");
                    }
                    moduleExecutionStatus = moduleExecutionStatus.replace("[module_name]", module.getName());
                    Logging.MODULES.addWithMultipleStyles(moduleExecutionStatus, color);
                }
            }
        }
    }

    // Ruft eine Methode des Modules auf
    public callMethod(modulename: string, methodname: string, ...args: any[]): any {
        var result: any = null;
        for (let i = 0; i < this.modules.length; i++) {
            if (this.modules[i].getName() == modulename) {
                result = this.modules[i].callMethod(methodname, args);

                break;
            }
        }
        return result;
    }

    public log(message: string, outputStyle: string = ""): void {
        Logging.GENERAL_LOG.add(message, outputStyle, false);
    }

    public error( message: string, useStrackTrace: boolean = true, outputStyle: string = "" ): void {
        Logging.ERROR_LOG.add( message, outputStyle, useStrackTrace );
    }

    public parseTextToJson( text:string ): Object {
        let result: Object = null;
        try {
            result = JSON.parse( text );
        } catch(error) {
            console.error(error);
        }
        return result;
    }

    public getGlobalConfig(key:string): any {
        return Json.getSubobject( this.globalConfigurations, key );
    }

    // Globale Funktionen
    public findModule(name: string): Module<Model> // -> Zeigt ein Objekt mit allen Infos des Modules an
    {
        var module: Module<Model> = null;
        for (let i = 0; i < this.modules.length; i++) {
            if (this.modules[i].getName() == name) {
                module = this.modules[i];
                break;
            }
        }
        return module;
    }

    public callGlobalEventFunction( eventName: string ): void {
        Windows.callGlobalFunction( "on" + Strings.beginWithUppercase( eventName ), false );
    }

    public callGlobalEventFunctionWithArgs( eventName: string, ...args: any[] ): void {
        Windows.callGlobalFunction("on" + Strings.beginWithUppercase( eventName ), false, args);
    }

    public getModule( name: string, id: number ): Module<Model> {
        if ( Module.checkControllerAccess( name, id ) ) {
            return this.findModule( name );
        } else {
            return null;
        }
    }

    public checkModuleImplementation( name: string, properties: Array<string> ): boolean {
        let module: Module<Model> = this.findModule( name );
        if ( module != null ) {
            return Interfaces.implements( module, properties );
        } else {
            return false;
        }
    }

    public ModuleInfo( moduleName: string ): void {
        let mod: Module<Model> = this.findModule( moduleName );
        if ( mod != null ) {
            let moduleExecutionStatus: any = mod.getExecutionStatus();
            let showExecutionInformation = moduleExecutionStatus == ModuleExecutionStatus.SUCCESSFULL || moduleExecutionStatus == ModuleExecutionStatus.CATCHED_ERROR;
            if ( showExecutionInformation ) {
                mod.printInfo();
            }
        } else {
            console.log( "Dieses Modul wurde nicht gefunden oder nicht geladen" );
        }
    }

    public moduleInit( moduleName: string, reInit: boolean = false ) {
        let mod: Module<Model> = this.findModule( moduleName );
        if ( mod != null ) {
            this.initModule(mod, reInit);
            console.log( "OK" );
        } else {
            console.log( "Dieses Modul wurde nicht gefunden oder nicht geladen" );
        }
    }

    // Setter
    public allowCookies(): void {
        this.flagAllowCookies = true;
        this.initModules();
    }

    // Getter
    public getLanguageCode(): string {
        return this.language.getValue();
    }

    public getLanguageID(): number {
        return this.language.getKey();
    }

    public getTemplate(): string {
        return this.template;
    }

    public getKontaktKey(): string {
        return this.sKontaktKEY;
    }

    public getKontaktID(): string {
        return this.sKontaktID;
    }

    public getTickcount(): string {
        return this.sTICKCOUNT;
    }
}